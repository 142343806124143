<template>
  <section>
    <breadcrumb :title="$t('nomenclators')" :subtitle="$t('agent_group')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div @click="filter=!filter" class="ml-25">
            <b-icon type="is-purple" size="is-medium" :icon="filter_name"></b-icon>
          </div>
        </div>
        <div class="column is-flex is-justify-content-flex-end mr-10">
          <button @click="isModalCurrencyActive = !isModalCurrencyActive" class="button new is-pink-light">
            <span class="mdi mdi-plus"></span>
            {{ $t('group') }}
          </button>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts"
          ref="table"
          :paginated="false"
          :per-page="itemsPerPage"
          detailed
          :show-detail-icon="false"
          detail-key="ci"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">

        <b-table-column field="name" :label="$t('name')" v-slot="props">
          <template>
            <a class="col name" @click="props.toggleDetails(props.row)">
              {{ props.row.name }}
            </a>
          </template>
        </b-table-column>

        <b-table-column field="symbol" :label="$t('currency')" sortable v-slot="props">
       <span class="symbol">
         {{ props.row.identificationNumber }}
       </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('percent')" sortable v-slot="props">
        <span class="col exchange_rate">
           {{ props.row.phoneNumber }}
        </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('amount_min')" sortable v-slot="props">
        <span class="col exchange_rate">
           {{ props.row.phoneNumber }}
        </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('utility_type')" sortable v-slot="props">
        <span class="col exchange_rate">
           {{ props.row.phoneNumber }}
        </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('fixed_utility')" sortable v-slot="props">
        <span class="col exchange_rate">
           {{ props.row.phoneNumber }}
        </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('utility')" sortable v-slot="props">
        <span class="col exchange_rate">
           {{ props.row.phoneNumber }}
        </span>
        </b-table-column>

        <b-table-column field="commission" :label="$t('active')" sortable v-slot="props">
          <div class="data-check">
            <b-checkbox type="is-success" v-model="props.row.isActive"></b-checkbox>
          </div>
        </b-table-column>

        <b-table-column :label="$t('actions')">
          <div class="is-flex">
            <div>
              <b-icon type="is-green-accent" icon="pencil" class="mx-4 cursor-pointer"></b-icon>
            </div>
          </div>

        </b-table-column>

        <template #detail="props">
          <div class="columns">
            <div class="column is-3 is-flex is-flex-direction-column">
              <span class="details-text">{{ $t('id') }}</span>
              <span class="details-text">{{ $t('created') }}</span>
              <span class="details-text">{{ $t('updated') }}</span>
            </div>
            <div class="column is-8 is-flex is-flex-direction-column">
              <span class="details-data">{{ props.row.id }}</span>
              <span class="details-data">{{ props.row.created }}</span>
              <span class="details-data">{{ props.row.updated }}</span>
            </div>
          </div>

        </template>
      </b-table>
      <paginate :count="totalRecords" :per_page="itemsPerPage" :current-page="currentPage"/>
    </section>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {mapActions} from "vuex";

export default {
  name: "AgentGroupComponent",
  components: {Paginate, Breadcrumb},
  data() {
    return {
      filter_name: 'filter-outline',
      filter: false,
      isModalCurrencyActive: false,
      propsModals: {},
      desserts: [],
      itemsPerPage: 0,
      currentPage: 0,
      totalPages: 0,
      totalRecords: 0,
    }
  },
  methods: {
    ...mapActions({
      fetchCurrencies: ''
    }),
    setPropsModals(newProp) {
      this.propsModals = newProp
      this.isModalCurrencyActive = true
    },
  }
}
</script>

<style scoped>

</style>